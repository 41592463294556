import { useRef } from 'react'

import {
    BUTTON_SMALL_STYLES,
    CONTENT_CONTAINER_STYLES,
    HEADING_3_STYLES,
    PARAGRAPH_SMALL_STYLES,
} from 'src/libs/design-system'
import { InView, useRenderAfterMount, useWindowSize } from 'src/libs/react-utils'
import {
    getRemSize,
    getRotatedGradientColors,
    isMinWidthXl,
    twMerge,
    useIsMinWidth3xl,
    useIsMinWidthMd,
} from 'src/libs/tailwind-css'
import { easeInOutQuadratic, IS_BROWSER } from 'src/libs/utils'

import { TeaserCard, TeaserCardProps } from './teaser-card'

interface TeaserCardSliderProps {
    title: string
    description: string
    cards: TeaserCardProps[]
}

const THRESHOLDS = Array.from({ length: 100 }, (_, index) => index / 100)

export function TeaserCardSlider({ title, description, cards }: TeaserCardSliderProps) {
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const isMinWidthMd = useIsMinWidthMd(false)
    const isMinWidth3xl = useIsMinWidth3xl(false)
    const { width } = useWindowSize(IS_BROWSER ? window.innerWidth : undefined)

    // Necessary because rootMargin of InView will always be undefined during first render.
    useRenderAfterMount()

    return (
        <article className={CONTENT_CONTAINER_STYLES}>
            <div
                ref={scrollContainerRef}
                className="md:mx-[-7.5vw] xl:mx-[-10vw] grid gap-y-8 gap-x-6 md:grid-flow-col md:grid-cols-[calc(7.5vw+17rem)] xl:grid-cols-[calc(10vw+17rem)] md:overflow-x-auto snap-x snap-proximity isolate no-scrollbar"
            >
                <div className="grid gap-6 content-center md:py-8 md:sticky md:left-0 md:z-10 md:pl-[7.5vw] xl:pl-[10vw]">
                    <div className="grid gap-[0.9rem]">
                        <h2 className={twMerge(HEADING_3_STYLES, 'sm:w-[90%]')}>{title}</h2>
                        <p className={PARAGRAPH_SMALL_STYLES}>{description}</p>
                    </div>
                    <div className="hidden md:grid grid-flow-col gap-2 justify-start">
                        <TeaserCardSliderButton
                            title="Scroll to previous"
                            onClick={() =>
                                scrollContainerRef.current?.scrollBy({
                                    left: -16 * getRemSize(width),
                                    behavior: 'smooth',
                                })
                            }
                        >
                            ←
                        </TeaserCardSliderButton>
                        <TeaserCardSliderButton
                            title="Scroll to next"
                            onClick={() =>
                                scrollContainerRef.current?.scrollBy({
                                    left: 16 * getRemSize(width),
                                    behavior: 'smooth',
                                })
                            }
                        >
                            →
                        </TeaserCardSliderButton>
                    </div>
                </div>
                <div className="mx-[-5vw] md:mx-0 snap-x snap-mandatory overflow-x-auto md:overflow-visible no-scrollbar">
                    <div className="grid">
                        <ul className="px-[5vw] md:pl-0 md:pr-[7.5vw] xl:pr-[10vw] grid grid-flow-col gap-4 auto-cols-[calc(90vw-1rem)] md:auto-cols-[15rem]">
                            {cards.map((card) => (
                                <li
                                    key={card.title}
                                    className="snap-center md:snap-start md:scroll-ml-[calc(7.5vw+17rem+1.5rem)] xl:scroll-ml-[calc(10vw+17rem+1.5rem)]"
                                    style={getRotatedGradientColors('green')}
                                >
                                    <InView
                                        disabled={!isMinWidthMd}
                                        root={scrollContainerRef}
                                        rootMargin={getRootMargin(
                                            width,
                                            isMinWidth3xl,
                                            scrollContainerRef.current?.offsetWidth
                                        )}
                                        threshold={THRESHOLDS}
                                        onIntersect={(entry) => {
                                            const styles = (entry.target as HTMLDivElement).style
                                            const easedValue = easeInOutQuadratic(
                                                entry.intersectionRatio
                                            )

                                            styles.opacity = easedValue.toString()
                                            styles.transform = `scale(${easedValue / 4 + 0.75})`
                                        }}
                                        className="h-full w-full"
                                    >
                                        <TeaserCard {...card} />
                                    </InView>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    )
}

function TeaserCardSliderButton(
    props: Omit<React.ComponentPropsWithoutRef<'button'>, 'className'>
) {
    return (
        <button
            {...props}
            className={twMerge(
                BUTTON_SMALL_STYLES,
                'p-2 rounded-[0.7rem] bg-grey-700/5 hover:bg-grey-700/10 transition-colors leading-none'
            )}
        />
    )
}

function getRootMargin(
    windowWidth: number,
    isMinWidth3xl: boolean,
    scrollContainerWidth: number | undefined
) {
    if (!Number.isFinite(windowWidth) || !scrollContainerWidth) {
        return
    }

    const remSize = getRemSize(windowWidth)
    const contentContainerPaddingLeft = (windowWidth * (isMinWidthXl(windowWidth) ? 10 : 7.5)) / 100
    const sliderDescriptionWidth = (17 + 1.5) * remSize
    const cardWidth = 15 * remSize
    const cardGapWidth = remSize
    const smallInfinity = 10_000

    const marginLeft = -contentContainerPaddingLeft - sliderDescriptionWidth
    const marginRight = isMinWidth3xl
        ? -scrollContainerWidth - marginLeft + 3 * cardWidth + 2 * cardGapWidth
        : smallInfinity

    return `0px ${marginRight}px 0px ${marginLeft}px`
}
