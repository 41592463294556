import { Modal } from 'src/libs/design-system'

export interface ModalVideoProps {
    isOpen: boolean
    setIsOpen(v: boolean): void
    videoUrl: string
}

export function ModalVideo({ isOpen, setIsOpen, videoUrl }: ModalVideoProps) {
    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Overlay className="bg-black/90" />
            <Modal.OverlayClose close={() => setIsOpen(false)} />
            <Modal.Container variableHeight className="w-full md:w-[54rem]">
                <video
                    src={videoUrl}
                    autoPlay
                    playsInline
                    className="object-cover md:min-w-[54rem]"
                    controls
                    width={972}
                />
            </Modal.Container>
        </Modal>
    )
}
