import Image from 'next/image'

import { marblesFinishLineImage, marblesFinishLineVideo } from 'src/libs/assets'
import {
    CONTENT_CONTAINER_STYLES,
    HEADING_1_STYLES,
    PARAGRAPH_INTRO_STYLES,
} from 'src/libs/design-system'
import { Appear, LazyVideo } from 'src/libs/react-utils'
import { twMerge } from 'src/libs/tailwind-css'
import { PartnerLogos, RequestDemoButton } from 'src/libs/ui-shared'

interface ClosingSectionProps {
    title: string
    description: string
}

export function ClosingSection({ title, description }: ClosingSectionProps) {
    return (
        <Appear>
            <article className={twMerge(CONTENT_CONTAINER_STYLES, 'grid gap-16')}>
                <div className="text-center">
                    <div className="grid gap-6 max-w-xl mx-auto">
                        <h2 className={HEADING_1_STYLES}>{title}</h2>
                        <p className={PARAGRAPH_INTRO_STYLES}>{description}</p>
                    </div>
                    <div className="pt-12">
                        <RequestDemoButton />
                    </div>
                </div>
                <div className="w-full max-w-lg mx-auto">
                    <LazyVideo
                        {...marblesFinishLineVideo}
                        noLoop
                        placeholderImage={
                            <Image
                                src={marblesFinishLineImage}
                                alt="The marbles are crossing the finish line."
                                layout="responsive"
                                placeholder="blur"
                            />
                        }
                    />
                </div>
                <PartnerLogos />
            </article>
        </Appear>
    )
}
