import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getAnonymousId } from 'src/libs/analytics'
import { Button, HEADING_4_STYLES, Input, PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'
import { fetchJson } from 'src/libs/fetch'
import { CheckFilledIcon, CloseOutlinedIcon } from 'src/libs/icons'
import { useAutoFocusedRef } from 'src/libs/react-utils'
import { twMerge } from 'src/libs/tailwind-css'
import { run } from 'src/libs/utils'

interface RequestDemoModalProps {
    location?: string
    unmount(): void
}

export function RequestDemoModal({ location, unmount }: RequestDemoModalProps) {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <Transition as={Fragment} appear show={isOpen} afterLeave={unmount}>
            <Dialog className="fixed inset-0 z-10 overflow-y-auto" onClose={setIsOpen}>
                <div className="min-h-full grid grid-cols-1 grid-rows-1 items-center px-2 sm:px-4 py-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-grey-700/60" />
                    </Transition.Child>

                    <Transition.Child
                        enter="transition ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        className="transform pointer-events-none grid grid-cols-1 justify-items-center"
                    >
                        <RequestDemoModalInner location={location} close={() => setIsOpen(false)} />
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

interface FormData {
    name: string
    company: string
    email: string
    crm: string
    teamSize: string
}

interface RequestDemoModalInnerProps {
    location?: string
    close(): void
}

function RequestDemoModalInner({
    location = window.location.href,
    close,
}: RequestDemoModalInnerProps) {
    const { register, handleSubmit } = useForm<FormData>()
    const nameInputRef = useAutoFocusedRef()
    const [submitStatus, setSubmitStatus] = useState<'idle' | 'submitting' | 'success'>('idle')
    const [errors, setErrors] = useState<Record<string, string>>({})

    function submit(data: FormData) {
        if (submitStatus === 'submitting') {
            return
        }

        setSubmitStatus('submitting')
        setErrors({})

        fetchJson('POST', '/api/request-demo', {
            ...data,
            location,
            anonymousId: getAnonymousId(),
        }).then(
            ({ response, body }) => {
                if (!response.ok) {
                    setErrors((body as any).errors)
                    setSubmitStatus('idle')
                    return
                }

                setSubmitStatus('success')
            },
            () => {
                setErrors({ network: 'client-offline' })
                setSubmitStatus('idle')
            }
        )
    }

    return (
        <div className="pointer-events-auto w-full max-w-sm bg-white rounded-3xl p-5">
            <div className="flex justify-between items-center pb-4">
                <Dialog.Title className={HEADING_4_STYLES}>Book demo</Dialog.Title>
                <Button
                    title="Close"
                    aria-label="Close"
                    className="flex-none hover:bg-grey-50 p-2"
                    onClick={close}
                >
                    <CloseOutlinedIcon className="w-4 h-4" />
                </Button>
            </div>
            <div className="relative">
                <form
                    className={clsx(
                        'grid justify-items-end gap-2',
                        submitStatus === 'success' && 'invisible'
                    )}
                    onSubmit={handleSubmit(submit)}
                >
                    <Input
                        placeholder="Name"
                        aria-label="Name"
                        type="text"
                        required
                        error={Boolean(errors.name)}
                        {...run(() => {
                            const registerProps = register('name')

                            return {
                                ...registerProps,
                                ref: (node: HTMLInputElement | null) => {
                                    ;(nameInputRef.current as any) = node
                                    registerProps.ref(node)
                                },
                            }
                        })}
                    />
                    <Input
                        placeholder="Business email"
                        aria-label="Business email"
                        // We use an ID with `_search` at the end to prevent 1Password extension from filling this field
                        id="book_demo_modal_business_email_search"
                        type="email"
                        required
                        error={Boolean(errors.email)}
                        {...register('email')}
                    />
                    <Input
                        placeholder="Which CRM do you use?"
                        aria-label="Which CRM do you use?"
                        type="text"
                        required
                        error={Boolean(errors.crm)}
                        {...register('crm')}
                    />
                    <Input
                        placeholder="How big is your sales team?"
                        aria-label="How big is your sales team?"
                        type="text"
                        required
                        error={Boolean(errors.teamSize)}
                        {...register('teamSize')}
                    />
                    <Button
                        variant="primary"
                        arrowRight
                        type="submit"
                        loading={submitStatus === 'submitting'}
                    >
                        Submit
                    </Button>
                </form>
                {submitStatus === 'success' && (
                    <div className={twMerge(PARAGRAPH_SMALL_STYLES, 'absolute inset-0 flex')}>
                        <div className="flex-none grid place-items-center w-[1.2rem] h-[1.2rem] rounded-full mr-2 mt-[0.05rem] text-green-500 bg-green-50">
                            <CheckFilledIcon className="w-[0.8rem] h-[0.8rem]" />
                        </div>
                        Thank you.
                        <br />
                        We will get in contact with you soon.
                    </div>
                )}
            </div>
        </div>
    )
}
