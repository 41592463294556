/* eslint-disable @next/next/no-img-element */
export function ProductHuntUpvoteBadge({ className }: { className?: string }) {
    return (
        <a
            href="https://www.producthunt.com/posts/laserfocus?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-laserfocus"
            target="_blank"
            rel="noreferrer"
            className={className}
        >
            <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=351212&theme=light"
                alt="Laserfocus - Beautiful&#0032;UX&#0032;for&#0032;Salesforce&#0032;users | Product Hunt"
                style={{ width: 250, height: 54 }}
                width="250"
                height="54"
            />
        </a>
    )
}
