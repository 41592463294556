import clsx from 'clsx'
import React from 'react'

import {
    CONTENT_CONTAINER_STYLES,
    HEADING_2_STYLES,
    LinkButton,
    PARAGRAPH_INTRO_STYLES,
} from 'src/libs/design-system'
import { Appear } from 'src/libs/react-utils'
import { twMerge } from 'src/libs/tailwind-css'

export interface ColoredBackgroundSectionProps {
    backgroundClassName?: string
    className?: string
    children: React.ReactNode
}

export function ColoredBackgroundSection({
    backgroundClassName,
    children,
    className,
}: ColoredBackgroundSectionProps) {
    return (
        <Appear
            {...(backgroundClassName && {
                classNameVisible: 'transition-all',
                styleHidden: { clipPath: 'inset(5rem 15% 5rem 15%)' },
                styleVisible: { clipPath: 'inset(0)' },
            })}
        >
            <article
                className={clsx(
                    backgroundClassName,
                    backgroundClassName && 'py-32 md:py-40',
                    className
                )}
            >
                {children}
            </article>
        </Appear>
    )
}

ColoredBackgroundSection.PrimaryContent = PrimaryContent
function PrimaryContent({ children }: { children: React.ReactNode }) {
    return (
        <div
            className={twMerge(
                CONTENT_CONTAINER_STYLES,
                'grid gap-6 text-center justify-items-center max-w-xl'
            )}
        >
            {children}
        </div>
    )
}

ColoredBackgroundSection.SecondaryContent = SecondaryContent
function SecondaryContent({ children }: { children: React.ReactNode }) {
    return <div className="xl:max-w-[66rem] mx-auto relative pt-12">{children}</div>
}

ColoredBackgroundSection.Title = Title
function Title({ children }: { children: string }) {
    return <h2 className={HEADING_2_STYLES}>{children}</h2>
}

ColoredBackgroundSection.Description = Description
function Description({ children }: { children: string }) {
    return <p className={PARAGRAPH_INTRO_STYLES}>{children}</p>
}

ColoredBackgroundSection.CTA = CTA
function CTA({ label, href }: { label: string; href: string }) {
    return (
        <LinkButton href={href} size="large" arrowRight>
            {label}
        </LinkButton>
    )
}
