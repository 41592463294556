import {
    CONTENT_CONTAINER_STYLES,
    Accordion,
    HEADING_2_STYLES,
    LinkButton,
    PARAGRAPH_INTRO_STYLES,
} from 'src/libs/design-system'
import { Appear } from 'src/libs/react-utils'
import { twMerge } from 'src/libs/tailwind-css'

interface FaqSectionProps {
    title: string
    description: string
    cta?: {
        label: string
        href: string
    }
    elements: FaqElement[]
}
interface FaqElement {
    question: string
    answer: string | string[]
}

export function FaqSection({ title, description, cta, elements }: FaqSectionProps) {
    return (
        <Appear>
            <article className={CONTENT_CONTAINER_STYLES}>
                <div className="max-w-xl mx-auto grid gap-6 text-center">
                    <h2 className={twMerge(HEADING_2_STYLES, 'md:w-4/5 mx-auto')}>{title}</h2>
                    <p className={PARAGRAPH_INTRO_STYLES}>{description}</p>
                    {cta && (
                        <LinkButton
                            href={cta.href}
                            size="large"
                            arrowRight
                            className="place-self-center"
                        >
                            {cta.label}
                        </LinkButton>
                    )}
                </div>
                <div className="pt-16 grid gap-6 max-w-lg mx-auto">
                    {elements.map(({ question, answer }) => (
                        <Accordion key={question} label={question}>
                            {typeof answer === 'string' ? (
                                answer
                            ) : (
                                <div className="grid gap-4">
                                    {answer.map((text, index) => (
                                        <p key={index}>{text}</p>
                                    ))}
                                </div>
                            )}
                        </Accordion>
                    ))}
                </div>
            </article>
        </Appear>
    )
}
