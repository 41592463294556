import clsx from 'clsx'
import { cloneElement } from 'react'

import { HEADING_5_STYLES, PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'
import { COLOR_HUES, theme, twMerge } from 'src/libs/tailwind-css'

export interface CardProps {
    image: React.ReactElement<{ className?: string; sizes?: string }>
    title: string
    description: string
    backgroundColor?: 'red' | 'purple' | 'blue' | 'yellow' | 'green'
}

const BACKGROUND_COLORS_CLASS_NAMES = {
    red: /* tw: */ 'bg-red-100',
    purple: /* tw: */ 'bg-purple-100',
    blue: /* tw: */ 'bg-blue-100',
    yellow: /* tw: */ 'bg-yellow-50',
    green: /* tw: */ 'bg-green-50',
}

export function Card({ image, title, description, backgroundColor }: CardProps) {
    const bgHue = backgroundColor && COLOR_HUES[backgroundColor]

    return (
        <article
            className={clsx(
                'snap-center bg-white rounded-3xl p-6 pb-8 grid gap-6 items-center content-start sm:grid-cols-[4fr,5fr] sm:pb-6 xl:grid-cols-1 xl:pb-8',
                backgroundColor && BACKGROUND_COLORS_CLASS_NAMES[backgroundColor]
            )}
            style={{
                boxShadow:
                    '0px 22px 48px rgba(0, 0, 0, 0.03), 0px 8.03036px 17.5208px rgba(0, 0, 0, 0.0206994), 0px 3.89859px 8.50602px rgba(0, 0, 0, 0.0166887), 0px 1.91116px 4.16981px rgba(0, 0, 0, 0.0133113), 0px 0.755676px 1.64875px rgba(0, 0, 0, 0.00930055)',
            }}
        >
            <div
                className="rounded-lg overflow-hidden"
                style={
                    bgHue
                        ? {
                              boxShadow: `0px -0.25rem 0px -0.25rem hsla(${bgHue}, 30%, 73%, 0.1), 0px 0.125rem 0.5rem hsla(${bgHue}, 30%, 73%, 0.25), 0px 0.25rem 1.5rem hsla(${bgHue}, 30%, 73%, 0.1)`,
                          }
                        : undefined
                }
            >
                {cloneElement(image, {
                    className: twMerge('h-full', image.props.className),
                    sizes: `(min-width: ${theme.screens.xl}) max(25vw, 400px), (min-width: ${theme.screens.sm}) 40vw, 100vw`,
                })}
            </div>
            <div>
                <h3 className={HEADING_5_STYLES}>{title}</h3>
                <p className={twMerge(PARAGRAPH_SMALL_STYLES, 'pt-[0.6rem]')}>{description}</p>
            </div>
        </article>
    )
}
