import Image from 'next/image'

import { ccpa, gdpr, salesforceOauth } from 'src/libs/assets'
import {
    CONTENT_CONTAINER_STYLES,
    HEADING_2_STYLES,
    LABEL_STYLES,
    PARAGRAPH_INTRO_STYLES,
    LinkButton,
} from 'src/libs/design-system'
import { Appear } from 'src/libs/react-utils'
import { twMerge } from 'src/libs/tailwind-css'

export function SecuritySection() {
    return (
        <Appear>
            <article
                className={twMerge(CONTENT_CONTAINER_STYLES, 'max-w-xl grid gap-16 text-center')}
            >
                <div className="grid gap-6 justify-items-center">
                    <h2 className={twMerge(HEADING_2_STYLES, 'sm:w-[90%]')}>
                        Your Salesforce data stays with you
                    </h2>
                    <p className={PARAGRAPH_INTRO_STYLES}>
                        Our precache technology only loads your Salesforce data onto your local machine. 
                        Therefore we don't need to store your Salesforce data which enables the highest security for any Enterprise.
                    </p>

                    <LinkButton
                        href="https://security.laserfocus.io"
                        target="_blank"
                        size="large"
                        arrowRight
                    >
                        See our security measures here
                    </LinkButton>
                </div>
                <div className="grid gap-4 text-grey-700/60">
                    <div className={LABEL_STYLES}>Enterprise ready security</div>
                    <ul className="flex flex-wrap gap-2 justify-center">
                        {[
                            { name: 'Salesforce Oauth', addition: 'SSO', icon: salesforceOauth },
                            { name: 'GDPR', addition: 'Compliant', icon: gdpr },
                            { name: 'CCPA', addition: 'Compliant', icon: ccpa },
                        ].map(({ name, addition, icon }) => (
                            <li
                                key={name}
                                className="grid grid-flow-col auto-cols-max gap-1 items-center py-2 pl-2 pr-4 rounded-2xl border border-blue-200"
                            >
                                <div className="w-8 h-8">
                                    <Image src={icon} unoptimized alt="" layout="responsive" />
                                </div>
                                <div className="text-[0.7rem] leading-[1.2] grid text-left">
                                    <strong>{name}</strong>
                                    {addition}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </article>
        </Appear>
    )
}
