// import { marblesDistributedOnPathsSmallImage } from 'src/libs/assets'
import {
    marblesAcceleratorSmallImage,
    marblesBumpySlideSmallImage,
    marblesDistributedOnPathsSmallImage,
    marblesInFunnelSmallImage,
    marblesOnStepsSmallImage,
    marblesPushedIntoTubeSmallImage,
} from 'src/libs/assets'
import { Appear } from 'src/libs/react-utils'

import { TeaserCardSlider } from './teaser-card-slider'

export function TeaserCardSliderSection() {
    return (
        <Appear>
            <TeaserCardSlider
                title="Explore helpful solutions"
                description="Learn how thought leaders are using Laserfocus to power their sales process."
                cards={[
                    {
                        image: {
                            data: marblesAcceleratorSmallImage,
                            alt: 'Accelerating marbles through looping',
                        },
                        label: 'Account Executives',
                        title: 'Manage all your deals in seconds',
                        ctaUrl: '/solutions/account-executives',
                    },
                    {
                        image: {
                            data: marblesInFunnelSmallImage,
                            alt: 'Marbles falling into a funnel',
                        },
                        label: 'Full Cycle Sales',
                        title: 'All your tools in one system',
                        ctaUrl: '/solutions/full-cycle-sales',
                    },
                    {
                        image: {
                            data: marblesOnStepsSmallImage,
                            alt: 'Marbles falling down steps',
                        },
                        label: 'Sales Operations',
                        title: 'Create easy to follow processes',
                        ctaUrl: '/solutions/sales-operations',
                    },
                    {
                        image: {
                            data: marblesDistributedOnPathsSmallImage,
                            alt: 'Marbles being distributed on paths',
                        },
                        label: 'Team Leads',
                        title: 'Coordinate your team hands-on',
                        ctaUrl: '/solutions/team-leads',
                    },
                    {
                        image: {
                            data: marblesPushedIntoTubeSmallImage,
                            alt: 'Marbles being pushed into tube',
                        },
                        label: 'SDRs / BDRs',
                        title: 'Handover without effort',
                        ctaUrl: '/solutions/sdrs-bdrs',
                    },
                    {
                        image: {
                            data: marblesBumpySlideSmallImage,
                            alt: 'Marbles being on a bumply slide',
                        },
                        label: 'Customer Success',
                        title: 'See the metrics that are relevant to you',
                        ctaUrl: '/solutions/customer-success',
                    },
                ]}
            />
        </Appear>
    )
}
