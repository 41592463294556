import { CONTENT_CONTAINER_STYLES } from 'src/libs/design-system'
import { useIsMinWidthSm } from 'src/libs/tailwind-css'

import { Card, CardProps } from './card'

interface CardsCollectionProps {
    cards: [CardProps, CardProps, CardProps]
}

export function CardsCollection({ cards }: CardsCollectionProps) {
    const isMinWidthSm = useIsMinWidthSm(true)

    return (
        <div className={CONTENT_CONTAINER_STYLES}>
            <div
                className="mx-[-5vw] sm:mx-0 snap-x snap-mandatory overflow-x-auto sm:overflow-x-visible no-scrollbar"
                style={
                    isMinWidthSm
                        ? undefined
                        : {
                              paddingBottom: 70,
                              marginBottom: -70,
                          }
                }
            >
                <div className="grid">
                    <div className="px-[5vw] sm:px-0 grid grid-flow-col gap-4 sm:gap-6 auto-cols-[calc(90vw-1rem)] sm:auto-cols-[100%] sm:grid-flow-row xl:grid-flow-col xl:auto-cols-fr">
                        {cards.map((card) => (
                            <Card key={card.title} {...card} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
