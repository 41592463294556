import {
    CONTENT_CONTAINER_STYLES,
    HEADING_1_STYLES,
    PARAGRAPH_INTRO_STYLES,
} from 'src/libs/design-system'
import { HtmlHead } from 'src/libs/html-head'
import { twMerge } from 'src/libs/tailwind-css'

interface IntroSectionSimpleProps {
    title: string
    description?: string
    children?: React.ReactNode
}

export function IntroSectionSimple({ title, description, children }: IntroSectionSimpleProps) {
    return (
        <header
            className={twMerge(
                CONTENT_CONTAINER_STYLES,
                'max-w-xl pt-16 grid gap-12 justify-items-center'
            )}
        >
            <HtmlHead title={title} description={description} />
            <div className="grid gap-6 text-center">
                <h1 className={HEADING_1_STYLES}>{title}</h1>
                {description && (
                    <p className={twMerge(PARAGRAPH_INTRO_STYLES, 'sm:w-[85%] mx-auto')}>
                        {description}
                    </p>
                )}
            </div>
            {children}
        </header>
    )
}
