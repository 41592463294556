import { useEffect, useState } from 'react'

import {
    bookingKitLogo,
    chocoLogo,
    harnessLogo,
    dittoLogo,
    eatfirstLogo,
    personioLogo,
    charthopLogo,
    upflowLogo,
    vaiLogo,
    wallboxLogo,
} from 'src/libs/assets'
import { InView, SwitchTransitionConcurrent } from 'src/libs/react-utils'

const ALL_LOGOS = [
    { name: 'Wallbox', logo: wallboxLogo, linkUrl: 'https://wallbox.com' },
    { name: 'Upflow', logo: upflowLogo, linkUrl: 'https://upflow.io' },
    { name: 'Choco', logo: chocoLogo, linkUrl: 'https://choco.com' },
    { name: 'Harness Giving', logo: harnessLogo, linkUrl: 'https://harnessgiving.com' },
    { name: 'Eatfirst', logo: eatfirstLogo, linkUrl: 'https://eatfirst.com' },
    { name: 'Personio', logo: personioLogo, linkUrl: 'https://personio.com' },
    { name: 'bookingkit', logo: bookingKitLogo, linkUrl: 'https://bookingkit.net' },
    { name: 'Charthop', logo: charthopLogo, linkUrl: 'https://www.charthop.com' },
    { name: 'Ditto', logo: dittoLogo, linkUrl: 'https://ditto.com' },
    { name: 'VAI', logo: vaiLogo, linkUrl: 'https://vaitrade.de' },
]

export function PartnerLogos() {
    const [{ visibleLogos }, setVisibleLogos] = useState(() => ({
        visibleLogos: ALL_LOGOS.slice(0, 6),
        lastChangedName: '',
    }))
    const [isInViewport, setIsInViewport] = useState(false)

    useEffect(() => {
        if (!isInViewport) {
            return
        }

        const triggerSwap = () => setVisibleLogos(swapRandomLogo)

        let interval: number

        function startSwapping() {
            window.clearInterval(interval)
            interval = window.setInterval(triggerSwap, 3000)
        }
        function stopSwapping() {
            window.clearInterval(interval)
        }

        window.addEventListener('focus', startSwapping, { passive: true })
        window.addEventListener('blur', stopSwapping, { passive: true })

        startSwapping()

        return () => {
            window.removeEventListener('focus', startSwapping)
            window.removeEventListener('blur', stopSwapping)
            stopSwapping()
        }
    }, [isInViewport])

    return (
        <InView
            className="grid grid-cols-2 gap-y-2 gap-x-[8%] place-items-center sm:grid-cols-3 sm:gap-x-[5%] xl:grid-cols-6 xl:gap-x-[2%]"
            onIntersect={({ isIntersecting }) => setIsInViewport(isIntersecting)}
        >
            {visibleLogos.map(({ name, logo, linkUrl }, index) => (
                <div key={index} className="w-full relative h-16">
                    <SwitchTransitionConcurrent
                        enter="transition-opacity duration-500 delay-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="bg-white w-full absolute inset-0"
                    >
                        <a
                            key={name}
                            href={linkUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="h-full grid place-items-center grid-rows-1 mix-blend-luminosity opacity-50 hover:mix-blend-normal hover:opacity-100 focus-visible:mix-blend-normal focus-visible:opacity-100"
                        >
                            {/* eslint-disable-next-line @next/next/no-img-element */}
                            <img
                                src={logo.src || (logo as any)}
                                width={logo.width}
                                height={logo.height}
                                alt={`${name} logo`}
                                className="max-h-full w-auto"
                            />
                        </a>
                    </SwitchTransitionConcurrent>
                </div>
            ))}
        </InView>
    )
}

interface SwapRandomLogoProps {
    visibleLogos: typeof ALL_LOGOS
    lastChangedName: string
}

function swapRandomLogo({
    visibleLogos,
    lastChangedName,
}: SwapRandomLogoProps): SwapRandomLogoProps {
    const visibleLogosNames = new Set(visibleLogos.map((l) => l.name))

    const hiddenLogos = ALL_LOGOS.filter((l) => !visibleLogosNames.has(l.name))
    const randomHiddenLogo = hiddenLogos[getRandom(hiddenLogos.length)]!

    const visibleWithoutLastChanged = visibleLogos.filter((l) => l.name !== lastChangedName)
    const randomVisibleLogoName =
        visibleWithoutLastChanged[getRandom(visibleWithoutLastChanged.length)]!.name

    return {
        visibleLogos: visibleLogos.map((l) =>
            l.name === randomVisibleLogoName ? randomHiddenLogo : l
        ),
        lastChangedName: randomHiddenLogo.name,
    }
}

function getRandom(integer: number) {
    return Math.floor(Math.random() * integer)
}
