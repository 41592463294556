import { CheckFilledIcon } from 'src/libs/icons'
import { PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'

interface BenefitsProps {
    benefits: string[]
    className?: string
    color: 'blue' | 'purple' | 'red' | 'yellow' | 'green'
}

const COLOR_STYLES = {
    blue: /*tw:*/ 'text-blue-500 bg-blue-100',
    purple: /*tw:*/ 'text-purple-400 bg-purple-100',
    red: /*tw:*/ 'text-red-500 bg-red-100',
    yellow: /*tw:*/ 'text-yellow-300 bg-yellow-50',
    green: /*tw:*/ 'text-green-500 bg-green-50',
}

export function Benefits({ benefits, className, color }: BenefitsProps) {
    return (
        <ul className={twMerge('grid gap-2', className)}>
            {benefits.map((benefit) => (
                <li key={benefit} className={twMerge(PARAGRAPH_SMALL_STYLES, 'flex')}>
                    <div
                        className={twMerge(
                            COLOR_STYLES[color],
                            'flex-none grid place-items-center w-[1.2rem] h-[1.2rem] rounded-full mr-2 mt-[0.05rem]'
                        )}
                    >
                        <CheckFilledIcon className="w-[0.8rem] h-[0.8rem]" />
                    </div>
                    {benefit}
                </li>
            ))}
        </ul>
    )
}
