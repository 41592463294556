import { useState } from 'react'
import Image from 'next/image'

import { LinkButton } from 'src/libs/design-system'

import { ColoredBackgroundSection } from '../color-background-section'
import { ModalVideo } from '../modal-video/model-video'
import { ProductHuntUpvoteBadge } from '../producthunt/producthunt-upvote-badge'

interface DemoVideoSectionProps {
    thumbnail: {
        alt: string
        image: StaticImageData
    }
    videoUrl: string
    title: string
    description: string
    backgroundClassName: string
}

export function DemoVideoSection({
    thumbnail,
    videoUrl,
    title,
    description,
    backgroundClassName,
}: DemoVideoSectionProps) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <ColoredBackgroundSection
            className="pt-12 pb-8 md:pt-16 md:pb-12"
            backgroundClassName={backgroundClassName}
        >
            <ColoredBackgroundSection.PrimaryContent>
                <ProductHuntUpvoteBadge />
                <ColoredBackgroundSection.Title>{title}</ColoredBackgroundSection.Title>
                <ColoredBackgroundSection.Description>
                    {description}
                </ColoredBackgroundSection.Description>
            </ColoredBackgroundSection.PrimaryContent>

            <ColoredBackgroundSection.SecondaryContent>
                <div className="w-full max-w-2xl mx-auto px-4">
                    <Image
                        onClick={() => setIsOpen(true)}
                        className="rounded-3xl cursor-pointer"
                        src={thumbnail.image.src}
                        alt={thumbnail.alt}
                        height={thumbnail.image.height}
                        width={thumbnail.image.width}
                        layout="responsive"
                    />

                    <ModalVideo videoUrl={videoUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
                <div className="flex justify-center mt-8 md:mt-12">
                    <LinkButton
                        variant="primary"
                        size="large"
                        arrowRight
                        href="https://app.laserfocus.io/signup"
                    >
                        Try for free
                    </LinkButton>
                </div>
            </ColoredBackgroundSection.SecondaryContent>
        </ColoredBackgroundSection>
    )
}
