import { ColoredBackgroundSection } from '../color-background-section'

import { CardsCollection } from './cards-collection'

interface ThreeCardsSectionProps {
    cards: Parameters<typeof CardsCollection>[0]['cards']
    title: string
    description: string
}

export function ThreeCardsSection({ cards, title, description }: ThreeCardsSectionProps) {
    return (
        <ColoredBackgroundSection>
            <ColoredBackgroundSection.PrimaryContent>
                <ColoredBackgroundSection.Title>{title}</ColoredBackgroundSection.Title>
                <ColoredBackgroundSection.Description>
                    {description}
                </ColoredBackgroundSection.Description>
            </ColoredBackgroundSection.PrimaryContent>
            <CardsCollection cards={cards} />
        </ColoredBackgroundSection>
    )
}
