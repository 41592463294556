export async function fetchJson<ResponseBody = unknown, RequestBody = any>(
    method: 'POST' | 'GET',
    url: string,
    body: RequestBody,
    options: RequestInit = {}
) {
    const response = await fetch(url, {
        method,
        headers: {
            'content-type': 'application/json',
            ...options.headers,
        },
        body: JSON.stringify(body),
        ...options,
    })

    const responseBody: ResponseBody = await response.json()

    return {
        response,
        body: responseBody,
    }
}
