import Image from 'next/image'

import { NextImage } from 'src/libs/types'
import { LABEL_STYLES, PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'

interface TestimonialProps {
    companyLogo: NextImage
    companyName: string
    description: string
    authorImage: NextImage
    authorName: string
    authorInfo: string
}

export function Testimonial({
    companyLogo,
    companyName,
    description,
    authorImage,
    authorName,
    authorInfo,
}: TestimonialProps) {
    return (
        <article className="grid gap-4">
            <div className="bg-white">
                {/* Can't use Next Image for height-constrained responsive images. Also this is an SVG image, so either way unoptimized */}
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    src={companyLogo.src || (companyLogo as any)}
                    width={companyLogo.width}
                    height={companyLogo.height}
                    alt={`${companyName} logo`}
                    className="h-10 mix-blend-luminosity opacity-50 w-auto"
                />
            </div>

            <div className="grid gap-6 text-grey-700/60 justify-items-start">
                <p className={twMerge(PARAGRAPH_SMALL_STYLES, 'italic w-4/5')}>{description}</p>
                <footer className="grid grid-flow-col gap-[0.8rem] items-center content-start">
                    <div className="relative w-12 h-12 rounded-full overflow-hidden">
                        <Image src={authorImage} alt="" layout="fill" sizes="3rem" />
                    </div>
                    <div className={twMerge(LABEL_STYLES, 'grid gap-[0.2rem]')}>
                        <div>{authorName}</div>
                        <div className="font-bold">{authorInfo}</div>
                    </div>
                </footer>
            </div>
        </article>
    )
}
