import Image from 'next/image'

import { HEADING_5_STYLES, LABEL_STYLES, LinkButton } from 'src/libs/design-system'
import { theme, twMerge } from 'src/libs/tailwind-css'
import { NextImage } from 'src/libs/types'

export interface TeaserCardProps {
    image?: {
        data: NextImage
        alt: string
    }
    /** Text which is above title */
    label: string
    title: string
    ctaUrl?: string
}

export function TeaserCard({ image, label, title, ctaUrl }: TeaserCardProps) {
    return (
        <article className="h-full w-full bg-green-50 rounded-3xl overflow-hidden grid grid-rows-[auto,minmax(0,1fr)]">
            {image ? (
                <Image
                    src={image.data}
                    alt={image.alt}
                    layout="responsive"
                    placeholder="blur"
                    sizes={`(min-width: ${theme.screens.md}) 15rem, 100vw`}
                />
            ) : (
                <div />
            )}
            <div className="px-6 pt-6 pb-4 grid content-between justify-items-start gap-4">
                <div className="grid gap-1">
                    <div className={twMerge(LABEL_STYLES, 'text-grey-700/75')}>{label}</div>
                    <h3 className={HEADING_5_STYLES}>{title}</h3>
                </div>
                {ctaUrl && (
                    <LinkButton href={ctaUrl} arrowRight alignLeft>
                        Learn more
                    </LinkButton>
                )}
            </div>
        </article>
    )
}
