import { Transition } from '@headlessui/react'
import Image from 'next/image'
import { useState } from 'react'

import {
    CONTENT_CONTAINER_STYLES,
    PARAGRAPH_INTRO_STYLES,
    HEADING_1_STYLES,
    PARAGRAPH_STYLES,
} from 'src/libs/design-system'
import { InView, useIsAppHydrated } from 'src/libs/react-utils'
import { theme, twMerge, useIsMinWidthMd } from 'src/libs/tailwind-css'
import { NextImage, Video } from 'src/libs/types'
import { HtmlHead } from 'src/libs/html-head'

interface IntroSectionProps {
    image: NextImage
    imageAlt: string
    video?: Video
    label?: string
    title: string
    description: string
    children?: React.ReactNode
}

export function IntroSection({
    image,
    imageAlt,
    video,
    label,
    title,
    description,
    children,
}: IntroSectionProps) {
    const isAppHydrated = useIsAppHydrated()
    const [isIntersectingImage, setIsIntersectingImage] = useState(false)
    const [isPlayingVideo, setIsPlayingVideo] = useState(false)
    const isMinWidthMd = useIsMinWidthMd()

    const aspectRatioElement = video || image
    const aspectRatio = aspectRatioElement.width / aspectRatioElement.height
    const maxHeightInVh = isMinWidthMd ? 100 : 60
    const shouldRenderVideo = video && isAppHydrated && isIntersectingImage

    return (
        <header
            className={twMerge(
                CONTENT_CONTAINER_STYLES,
                'grid gap-4 md:gap-0 grid-rows-1 grid-cols-1 md:grid-cols-2 items-center isolate'
            )}
        >
            <HtmlHead
                title={[title, label].filter(Boolean).join(' | ')}
                description={description}
            />
            <InView
                disabled={isIntersectingImage}
                onIntersect={({ isIntersecting }) => isIntersecting && setIsIntersectingImage(true)}
                rootMargin="500px"
                className="relative md:col-start-2 md:mb-auto max-h-[60vh] md:max-h-screen w-full h-full mx-auto"
                style={{
                    maxWidth: `${maxHeightInVh * aspectRatio}vh`,
                    // Browsers which don't support aspect-ratio will get a layout shift on the transition between `shouldRenderVideo === true` and `isPlayingVideo === true`
                    aspectRatio: `${aspectRatioElement.width} / ${aspectRatioElement.height}`,
                }}
            >
                {shouldRenderVideo && (
                    <video
                        src={video.src}
                        autoPlay
                        muted
                        loop
                        playsInline
                        onPlay={() => setIsPlayingVideo(true)}
                        className={
                            isPlayingVideo ? undefined : 'absolute left-0 top-0 w-full h-full'
                        }
                    />
                )}
                <Transition
                    show={!isPlayingVideo}
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={isPlayingVideo ? 'absolute left-0 top-0 w-full h-full' : undefined}
                >
                    <Image
                        src={image}
                        alt={imageAlt}
                        layout="responsive"
                        placeholder="blur"
                        sizes={`(min-width: ${theme.screens.sm}) 50vw, 100vw`}
                        priority
                    />
                </Transition>
            </InView>

            <div className="text-center md:text-left md:row-start-1 md:-mr-24 md:py-16 lg:ml-20 z-10 grid gap-12">
                <div>
                    {label && <p className={twMerge(PARAGRAPH_STYLES, 'pb-2')}>{label}</p>}
                    <h1
                        className={twMerge(
                            HEADING_1_STYLES,
                            'md:max-w-lg sm:w-[90%] sm:mx-auto md:mx-0'
                        )}
                    >
                        {title}
                    </h1>
                    <p className={twMerge(PARAGRAPH_INTRO_STYLES, 'pt-6 md:max-w-lg')}>
                        {description}
                    </p>
                </div>
                {children}
            </div>
        </header>
    )
}
