import { useState } from 'react'

import { Button, LABEL_STYLES } from 'src/libs/design-system'
import { CreditCardOutlinedIcon } from 'src/libs/icons'
import { twMerge } from 'src/libs/tailwind-css'

import { RequestDemoModal } from './request-demo-modal'

interface RequestDemoButtonProps {
    renderButton?(openModal: () => void): React.ReactNode
}

export function RequestDemoButton({ renderButton = renderDefaultButton }: RequestDemoButtonProps) {
    const [isModalRendered, setIsModalRendered] = useState(false)

    return (
        <>
            {renderButton(() => setIsModalRendered(true))}
            {isModalRendered && <RequestDemoModal unmount={() => setIsModalRendered(false)} />}
        </>
    )
}

function renderDefaultButton(openModal: () => void) {
    return (
        <div className="grid place-content-center">
            <Button variant="primary" size="large" arrowRight onClick={openModal}>
                Book demo
            </Button>
            <div className={twMerge(LABEL_STYLES, 'text-grey-700/50 pt-[0.6rem] text-center')}>
                <CreditCardOutlinedIcon className="w-4 h-4 inline-block mr-2 align-text-bottom" />
                No Credit Card needed.
            </div>
        </div>
    )
}
