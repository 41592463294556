import Image from 'next/image'

import { CreditCardOutlinedIcon } from 'src/libs/icons'
import { theme, twMerge } from 'src/libs/tailwind-css'
import {
    LinkButton,
    CONTENT_CONTAINER_STYLES,
    HEADING_2_STYLES,
    PARAGRAPH_INTRO_STYLES,
    LABEL_STYLES,
} from 'src/libs/design-system'
import { marblesInLoopingImage, marblesInLoopingVideo } from 'src/libs/assets'
import { Appear, LazyVideo } from 'src/libs/react-utils'

interface BannerWithCtaSectionProps {
    title?: string
    description?: string
}

export function BannerWithCtaSection({
    title = 'Start directly on your own',
    description = 'Try Laserfocus for free. It’s ready in seconds and no admin is needed. We ensure the highest security and don’t store your Salesforce data.',
}: BannerWithCtaSectionProps) {
    return (
        <Appear
            classNameVisible="transition-all"
            styleHidden={{ clipPath: 'inset(5rem 15% 5rem 15%)' }}
            styleVisible={{ clipPath: 'inset(0)' }}
        >
            <article className="bg-green-50 pb-16 lg:pb-0 overflow-hidden grid gap-8 isolate">
                <div className="sm:w-full sm:max-w-lg sm:mx-auto lg:row-start-1 lg:col-start-1 lg:w-1/2">
                    <LazyVideo
                        {...marblesInLoopingVideo}
                        placeholderImage={
                            <Image
                                src={marblesInLoopingImage}
                                alt="Marbles running through loop"
                                layout="responsive"
                                sizes={`(min-width: 1408px) 704px, (min-width: ${theme.screens.lg}) 50vw, (min-width: ${theme.screens.sm}) 32rem, 100vw`}
                            />
                        }
                    />
                </div>
                <div
                    className={twMerge(
                        CONTENT_CONTAINER_STYLES,
                        'grid gap-12 text-center lg:text-left lg:row-start-1 lg:col-start-1 z-10 lg:grid-flow-col lg:justify-between lg:content-center'
                    )}
                >
                    <div className="grid gap-6 lg:w-80">
                        <h2 className={HEADING_2_STYLES}>{title}</h2>
                        <p className={PARAGRAPH_INTRO_STYLES}>{description}</p>
                    </div>
                    <div className="grid place-content-center">
                        <LinkButton
                            variant="primary"
                            size="large"
                            arrowRight
                            href="https://app.laserfocus.io/signup"
                        >
                            Get started for free
                        </LinkButton>
                        <div
                            className={twMerge(
                                LABEL_STYLES,
                                'text-grey-700/50 pt-[0.6rem] text-center'
                            )}
                        >
                            <CreditCardOutlinedIcon className="w-4 h-4 inline-block mr-2 align-text-bottom" />
                            No Credit Card needed.
                        </div>
                    </div>
                </div>
            </article>
        </Appear>
    )
}
